<template>
    <div class="green-section" :class="className">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'GreenBlock',
        props: ['className']
    }
</script>

<style lang="scss" scoped>

</style>