<template>
  <div>
    <GreenBlock>
      <v-container>
        <h1 class="page-title">JETZT NEU: Die eprimo Strompakete</h1>
        <div class="green-section__content">
          <div class="green-section--left">
            <div class="page-subtitle">Kaufen Sie Strompakete zu günstigen Festpreisen und schützen Sie sich so vor
              zukünftigen Preiserhöhungen – egal wie lange Sie benötigen, um diesen Strom zu verbrauchen.
            </div>
            <ul class="list-checkmark mb-0">
              <li>Keine Vertragslaufzeit</li>
              <li>Günstige feststehende Preise</li>
              <li>Strompakete sind unbegrenzt gültig</li>
              <li>100% grüner Strom</li>
              <li>Onlineprodukt, komplett digitale Abwicklung</li>
            </ul>
          </div>
          <div class="green-section--right">
            <img src="~@/assets/images/eprimo-face-2.svg" alt="eprimo-face">
          </div>
        </div>
      </v-container>
    </GreenBlock>
    <v-container>
      <div class="box-shadow mb-0">
        <h3 class="section__title">Haben Sie einen Code?</h3>
        <p>Bitte geben Sie Ihren persönlichen Aktionscode ein, den Sie in unserem Anschreiben erhalten haben.</p>
        <v-form
            ref="form"
            @submit.prevent="login"
            class="form-login"
        >
          <div class="form-input-block">
            <div class="form-placeholder">Ihr Aktionscode</div>
            <input
                class="form-input" :class="{'form-input--error': error || errorProp}"
                v-model="uuid"
                type="text"
                required
            >
            <p v-if="error || errorProp" class="form-error">
              {{ errorMessage }}
            </p>
          </div>
          <div class="text-center-mobile">
            <button
                class="btn"
                type="submit"
            >
              Zum Angebot
            </button>
          </div>
        </v-form>
      </div>
    </v-container>
  </div>
</template>

<script>
import GreenBlock from "@/components/GreenBlock";

export default {
  name: 'LoginPage',
  components: {
    GreenBlock
  },
  props: ['errorProp', 'uuidProp'],
  data() {
    return {
      uuid: '',
      error: false,
      errorMessage: 'Ein ungültiger Aktionscode. Bitte verwenden Sie den richtigen Aktionscode.'
    }
  },
  methods: {
    login() {
      this.sendRequest(process.env.VUE_APP_BACKEND_BASE_URL + 'user/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          uuid: this.uuid
        })
      })
          .then(res => res.json())
          .then(json => {
            if (json.success) {
              this.sendRequest(process.env.VUE_APP_BACKEND_BASE_URL + 'data/output/' + this.uuid, {
                method: 'GET',
              })
                  .then(res => res.json())
                  .then(json => {
                    if (!json.success) {
                      this.$router.push({name: 'error'});
                    } else {
                      this.sendRequest(process.env.VUE_APP_BACKEND_BASE_URL + 'user/code/' + this.uuid, {
                        method: 'GET'
                      })
                      .then(res => res.json())
                      .then(json => {
                        if (!json.success) {
                          this.$router.push({name: 'expiredCode'})
                        } else {
                          this.$router.push('/' + this.uuid)
                        }
                      })
                    }
                  })
            } else {
                this.error = true;
              }
          });
    }
  },
  mounted() {
    if (this.uuidProp) {
      this.uuid = this.uuidProp;
    }
  }
}
</script>

<style lang="scss" scoped>
.green-section--right {
  margin: -40px 37px -40px 0;
}

.form {
  &-input {
    border-radius: 4px;
    height: 40px;
    width: 100%;
    display: block;
    border: 1px solid $green-3;
    padding: 6px 12px;

    &:focus {
      box-shadow: inset 1px 1px rgba(0, 0, 0, .075), 0 0 5px rgba(180, 218, 4, .5);
    }

    &-block {
      width: 100%;
      margin-right: 30px;
    }

    &--error {
      border-color: $orange;
    }
  }

  &-error {
    margin-bottom: 0;
    color: $orange;
  }

  &-login {
    .btn {
      margin-top: 20px;
    }
  }

  &-placeholder {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 8px;
  }
}

@media (min-width: 768px) {
  .form {
    &-login {
      max-width: 650px;
      display: flex;
      align-items: flex-start;
      width: 100%;
      margin: 0 auto 20px;
    }

    &-login {
      .btn {
        margin-top: 36px;
      }
    }
  }
}
</style>
